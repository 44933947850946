import { AuctionRequest, FileParams, Patient } from "core/types";
import FileUploadStatusPanel from "dsl/ecosystems/PatientFiles/FileUploadStatusPanel";
import { useFilesFeatures } from "dsl/ecosystems/PatientFiles/useFilesFeature";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

type PatientFilesContextType = Omit<
  ReturnType<typeof useFilesFeatures>,
  "setUploadStates" | "uploadStates"
> & {
  setAuctionRequest: Dispatch<SetStateAction<AuctionRequest | undefined>>;
  setExternalId: Dispatch<SetStateAction<string | undefined>>;
  setParams: Dispatch<SetStateAction<FileParams | undefined>>;
};

const PatientFilesContext = createContext<PatientFilesContextType | undefined>(
  undefined,
);

export function PatientFilesProvider({ children }: { children: ReactNode }) {
  const [params, setParams] = useState<FileParams>();
  const [externalId, setExternalId] = useState<Patient["external_id"]>();
  const [auctionRequest, setAuctionRequest] = useState<AuctionRequest>();
  const { setUploadStates, uploadStates, ...filesFeature } = useFilesFeatures({
    params: params as FileParams,
    externalId: externalId,
    auctionRequest,
  });

  return (
    <PatientFilesContext.Provider
      value={{
        ...filesFeature,
        setParams,
        setExternalId,
        setAuctionRequest,
      }}
    >
      {children}
      <FileUploadStatusPanel
        fileUploadStates={uploadStates}
        setFileUploadStates={setUploadStates}
      />
    </PatientFilesContext.Provider>
  );
}

export function usePatientFilesContext({
  auctionRequest,
  externalId,
  params,
}: {
  auctionRequest?: AuctionRequest;
  externalId: Patient["external_id"];
  params: FileParams;
}) {
  const context = useContext(PatientFilesContext);
  if (!context) {
    throw new Error(
      "usePatientFilesContext must be used within a PatientFilesProvider",
    );
  }

  useEffect(() => {
    const { setAuctionRequest, setExternalId, setParams } = context;
    setAuctionRequest(auctionRequest);
    setParams(params);
    setExternalId(externalId);
  }, [auctionRequest, externalId, params]);

  return context;
}
